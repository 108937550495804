import { Link } from '@inertiajs/react'
import { Search, Menu } from 'lucide-react'
import { Button } from '@/components/ui/button'
import ApplicationLogo from '@/components/ApplicationLogo'
import Desktop from './Desktop'
import Mobile from './Mobile'
import LanguageSwitch from './LanguageSwitch'
import ThemeSwitch from './ThemeSwitch'
import SearchDrawer from './SearchDrawer'
import { usePage } from '@inertiajs/react'
import { useEffect, useState } from 'react'
import { motion, useScroll } from 'framer-motion'
import { cn } from '@/lib/utils'
import { PageProps } from '@/types'

export default function MainNavigation() {
  const { supportedLocales, navigation } = usePage<PageProps>().props
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const { scrollY } = useScroll()
  const [shouldShowEffect, setShouldShowEffect] = useState(false)
  const headerNavigation = navigation?.header || {}

  useEffect(() => {
    const unsubscribe = scrollY.on('change', (latest) => {
      setShouldShowEffect(latest > 0)
    })
    return () => unsubscribe()
  }, [scrollY])

  return (
    <motion.header
      className={cn(
        'lg:16 sticky top-0 z-50 mx-auto px-4 py-4 backdrop-blur transition-all duration-150 md:px-8 xl:px-32',
        shouldShowEffect ? 'border-b border-border bg-background/60 backdrop-blur' : ''
      )}
    >
      <div className="flex max-w-full items-center justify-between">
        <Link href="/" className="flex-shrink-0">
          <ApplicationLogo className="block h-9 w-auto px-2 transition-colors duration-300" />
        </Link>
        <nav className="hidden flex-grow justify-center md:flex">
          <Desktop navData={headerNavigation} />
        </nav>
        <div className="flex items-center space-x-4">
          {/* <div className="flex md:hidden lg:flex">
            <SearchDrawer />
          </div> */}
          <div className="hidden items-center space-x-4 lg:flex">
            {/* <LanguageSwitch locales={supportedLocales} /> */}
            <ThemeSwitch />
          </div>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-lg text-foreground transition-colors hover:text-primary supports-[backdrop-filter]:drop-shadow-sm lg:hidden"
            aria-label="Open menu"
            onClick={() => setIsMobileNavOpen(true)}
          >
            <span className="sr-only">Toggle Menu</span>
            <Menu className="h-5 w-5" />
          </Button>
        </div>
        <Mobile
          isOpen={isMobileNavOpen}
          setIsOpen={setIsMobileNavOpen}
          navData={headerNavigation}
        />
      </div>
    </motion.header>
  )
}
