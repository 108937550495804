'use client'

import React from 'react'
import { usePage } from '@inertiajs/react'
import { NavItem, PageProps } from '@/types'
import { LazySvg } from '@/components/custom/lazy-svg'
import useLocalizedValue from '@/hooks/use-localized-value'

const SocialIcon: React.FC<NavItem> = ({ name, href, icon }) => (
  <a href={href} className="group hover:delay-100" aria-label={`${name.en} icon`}>
    <LazySvg
      aria-hidden="true"
      name={icon || ''}
      className="transition-color size-8 text-muted-foreground duration-200 ease-in dark:text-muted lg:size-8 xl:size-10 [&>circle]:fill-current group-hover:[&>circle]:fill-secondary dark:group-hover:[&>circle]:fill-muted-foreground [&>path]:fill-white group-hover:[&>path]:fill-muted-foreground dark:group-hover:[&>path]:fill-secondary"
    />
  </a>
)

const FooterLink: React.FC<NavItem> = ({ href, name }) => (
  <a
    href={href}
    className="px-1.5 text-sm font-medium text-muted-foreground hover:text-primary md:px-2.5"
  >
    {useLocalizedValue(name)}
  </a>
)

export default function Footer() {
  const { navigation } = usePage<PageProps>().props
  const { Social: socialNavigation = [], default: defaultNavigation = [] } =
    navigation?.footer || {}

  return (
    <footer
      aria-labelledby="footer-heading"
      className="mx-auto flex max-w-screen-2xl flex-col bg-transparent md:pt-10"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="flex flex-col justify-center gap-2 border-t border-border p-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:px-10 xl:px-[100px]">
        <div className="order-2 flex items-center justify-center space-x-2 lg:order-none lg:space-x-4 rtl:space-x-reverse lg:rtl:space-x-reverse">
          {socialNavigation.map((item: NavItem) => (
            <SocialIcon key={item.name.en} {...item} />
          ))}
        </div>
        <div className="order-1 flex items-center justify-center divide-x divide-secondary-foreground/20 lg:order-none rtl:divide-x-reverse">
          {defaultNavigation
            .sort((a: NavItem, b: NavItem) => a.order - b.order)
            .map((item: NavItem) => (
              <FooterLink key={item.href} {...item} />
            ))}
        </div>
        <p className="order-3 text-center text-sm font-normal leading-5 text-body lg:order-none lg:inline-flex lg:items-center lg:max-lg:text-xs">
          &copy; {new Date().getFullYear()} Roshan. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
