import React, { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
import { Link } from '@inertiajs/react'
import { QuickAccessLink } from '@/types'
import { LanguageSensitiveLazySvg } from '@/components/custom/lazy-svg'
import { motion } from 'framer-motion'
import useLocalizedValue from '@/hooks/use-localized-value'
interface QuickAccessLinkItemProps {
  item: QuickAccessLink
  index: number
  totalItems: number
}

const QuickAccessLinkItem: React.FC<QuickAccessLinkItemProps> = ({ item, index, totalItems }) => {
  const MotionLink = motion.create(Link)

  return (
    <MotionLink
      key={item.order}
      href={item.href}
      initial={{ opacity: 0, y: index % 2 ? '-50%' : '50%' }}
      whileInView={{ opacity: 1, x: 0, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1, ease: 'easeOut' }}
      viewport={{ once: true }}
      className={cn(
        'group flex cursor-pointer flex-col items-center justify-center space-y-3 rounded-xl border border-border p-3 text-foreground transition-all duration-500 hover:border-primary hover:bg-primary hover:text-white hover:shadow-lg',
        'sm:h-28 md:h-24 lg:h-28 xl:h-36',
        'sm:rounded-3xl xl:rounded-3xl',
        'lg:w-full xl:w-auto',
        totalItems === 6 && index === 0 ? 'lg:rounded-l-3xl lg:rounded-r-none' : '',
        totalItems === 6 && index === 5 ? 'lg:rounded-l-none lg:rounded-r-3xl' : '',
        totalItems === 6 && index > 0 && index < 5 ? 'lg:rounded-none' : '',
        totalItems !== 6 ? 'lg:rounded-3xl' : '',
        totalItems === index + 2 || index === 1 ? 'sm:col-span-2 md:col-span-1' : 'col-span-1'
      )}
    >
      <LanguageSensitiveLazySvg
        name={item.icon}
        className="size-6 text-primary transition-colors group-hover:text-white dark:text-foreground sm:size-8 lg:size-10 xl:size-12"
      />
      <div className="text-center text-xs font-semibold sm:text-sm">
        {useLocalizedValue(item.name)}
      </div>
    </MotionLink>
  )
}

export default function QuickAccessLinks({ links }: { links: QuickAccessLink[] }) {
  const [sortedLinks, setSortedLinks] = useState<Array<QuickAccessLink & { key: string }>>([])

  useEffect(() => {
    const sorted = Object.entries(links)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([key, item]) => ({ key, ...item }))
    setSortedLinks(sorted)
  }, [links])

  const gridClassName = cn(
    'grid gap-3 sm:gap-4 lg:gap-0 xl:gap-6',
    sortedLinks.length < 6 ? 'justify-center' : '',
    sortedLinks.length === 1
      ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-1'
      : sortedLinks.length === 2
        ? 'grid-cols-2 sm:grid-cols-2 md:grid-cols-2'
        : sortedLinks.length === 3
          ? 'grid-cols-2 sm:grid-cols-3 md:grid-cols-3'
          : sortedLinks.length === 4
            ? 'grid-cols-2 sm:grid-cols-4 md:grid-cols-4'
            : sortedLinks.length === 5
              ? 'grid-cols-2 sm:grid-cols-4 md:grid-cols-5'
              : 'grid-cols-2 sm:grid-cols-4 md:grid-cols-6'
  )

  return (
    <div className={gridClassName}>
      {sortedLinks.map((item, index) => (
        <QuickAccessLinkItem
          key={item.order}
          item={item}
          index={index}
          totalItems={sortedLinks.length}
        />
      ))}
    </div>
  )
}
