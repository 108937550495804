import { Translatable } from '@/types'
import React from 'react'

interface RenderSafeHtmlProps {
  content: string
  className?: string
}

const RenderSafeHtml: React.FC<RenderSafeHtmlProps> = ({ content, className }) => {
  return <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
}

export default RenderSafeHtml
