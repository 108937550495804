import RenderSafeHtml from '@/components/custom/render-safe-html'
import useLocalizedValue from '@/hooks/use-localized-value'
import { usePage, Link, router } from '@inertiajs/react'
import { motion } from 'framer-motion'

export default function Show() {
  const { article } = usePage().props

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      className="relative mx-auto w-full"
    >
      <button
        onClick={() => router.visit('/news')}
        type="button"
        aria-label="Go back to articles"
        className="group mb-8 flex h-10 w-10 items-center justify-center self-end rounded-full bg-background shadow shadow-border ring-1 ring-border transition hover:ring-primary/50 lg:absolute lg:-mt-2 lg:mb-0 xl:-top-1.5 xl:mt-0 ltr:lg:-right-5 ltr:xl:right-0 rtl:lg:-left-5 rtl:xl:left-0"
      >
        <svg
          viewBox="0 0 16 16"
          fill="none"
          aria-hidden="true"
          className="h-4 w-4 stroke-foreground transition group-hover:stroke-primary rtl:rotate-180"
        >
          <path
            d="M7.25 11.25 3.75 8m0 0 3.5-3.25M3.75 8h8.5"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </button>

      <article>
        <header className="flex flex-col">
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-foreground sm:text-5xl">
            {useLocalizedValue(article.title)}
          </h1>
          <time
            dateTime="{article.published_at}"
            className="order-first flex items-center text-base text-caption"
          >
            <span className="h-4 w-0.5 rounded-full bg-caption"></span>
            <span className="ml-3">{article.published_at}</span>
          </time>
          {article.excerpt && (
            <p className="pt-4 text-foreground">{useLocalizedValue(article.excerpt)}</p>
          )}
          {article.media && (
            <img
              className="object-cover py-8"
              src={'/storage/' + useLocalizedValue(article.media) || ''}
              alt={useLocalizedValue(article.title) || ''}
            />
          )}
        </header>
        <RenderSafeHtml
          className="prose prose-base max-w-none dark:prose-invert"
          content={useLocalizedValue(article.html || {}) || ''}
        />
      </article>
    </motion.div>
  )
}
