'use client'

import { motion } from 'framer-motion'
import { cn } from '@/lib/utils'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Link } from '@inertiajs/react'
import { LazySvg } from '@/components/custom/lazy-svg'

interface NewsCardProps {
  id: number
  className?: string
  excerpt: string
  media: string
  publishedAt: string
  title: string
}

export default function NewsCard({
  id,
  className,
  excerpt,
  media,
  publishedAt,
  title,
}: NewsCardProps) {
  return (
    <Card className="group w-full overflow-hidden !rounded-[20px] border-none bg-card shadow-md transition-shadow duration-300 hover:shadow-lg sm:w-[300px] md:w-[350px] lg:w-[350px] xl:w-[350px]">
      <motion.div
        className={cn('flex h-[380px] flex-col gap-[22px]', className)}
        whileHover="hover"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <CardHeader className="relative h-[200px] overflow-hidden">
          <motion.div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${media})` }}
            variants={{
              initial: { scale: 1 },
              hover: { scale: 1.2 },
            }}
            transition={{
              duration: 0.3,
            }}
          />
        </CardHeader>
        <CardContent className="flex flex-col items-start justify-center gap-4 py-0">
          <p className="text-xs text-body">{publishedAt}</p>
          <CardTitle className="line-clamp-1 text-xl font-semibold leading-tight tracking-tighter text-foreground">
            {title}
          </CardTitle>
          <p className="line-clamp-3 text-sm font-medium text-body">{excerpt}</p>
        </CardContent>
      </motion.div>
      <CardFooter className="flex justify-end">
        <Link
          href={`/news/${id}`}
          className="flex items-center justify-center space-x-0.5 text-foreground outline-none group-hover:text-primary"
        >
          <span className="text-xs font-medium">More Details</span>
          <LazySvg name="arrow-right" className="size-2" />
        </Link>
      </CardFooter>
    </Card>
  )
}
