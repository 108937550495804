import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Bundle, Tariff, Translatable } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const convertComponentAsClassName = (input: string): string => {
  return input
    .split(/[^a-zA-Z0-9]/) // Split on non-alphanumeric characters
    .filter(Boolean) // Remove empty strings
    .map((word) => word.toLowerCase()) // Convert each word to lowercase
    .join('-') // Join words into a single string
}

/**
 * Converts a snake_case or kebab-case string to Title Case.
 * @param text The input string to convert
 * @returns The converted string in Title Case
 */
export function toTitleCase(text: string): string {
  return text
    .split(/[_-]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

/**
 * Calculates the font size for a title based on its length.
 *
 * @param {string} title - The title string.
 * @param {number} baseFontSize - The base font size for titles of length <= 10 (default 2.25rem).
 * @param {number} minFontSize - The minimum font size allowed (default 1.5rem).
 * @param {number} adjustmentFactor - The factor to reduce font size per character beyond 10 (default 0.1rem).
 * @returns {string} - The calculated font size in rem units.
 */
export const adjustFont = (
  title: string,
  baseFontSize: number = 2.25,
  minFontSize: number = 1.5,
  adjustmentFactor: number = 0.1
) => {
  const leng = title.length
  if (leng <= 10) {
    return `${baseFontSize}rem`
  }

  const adjustedFontSize = baseFontSize - (leng - 10) * adjustmentFactor
  return `${Math.max(minFontSize, adjustedFontSize)}rem`
}

type DeepLocalizedBundle<T> = {
  [K in keyof T]: T[K] extends Translatable<string>
    ? string
    : T[K] extends Array<Tariff>
      ? Array<DeepLocalizedTariff>
      : T[K] extends Record<string, any>
        ? DeepLocalizedBundle<T[K]>
        : T[K]
}

type DeepLocalizedTariff = {
  id: number
  name: string // Changed from Translatable<string> to string
  pivot: Record<string, any>
}

export const localizeBundle = <T extends Record<string, any>>(
  bundle: T,
  localizeValue: (value: Translatable<string>) => string
): DeepLocalizedBundle<T> => {
  return Object.entries(bundle).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length > 0 && 'name' in value[0]) {
      // Handle array of Tariffs
      acc[key as keyof T] = value.map((tariff) => ({
        ...tariff,
        name: localizeValue(tariff.name), // Directly localize the name here
      })) as DeepLocalizedBundle<T>[keyof T]
    } else if (typeof value === 'object' && value !== null) {
      if ('en' in value || 'ps' in value || 'fa' in value) {
        // Assume it's a Translatable object
        acc[key as keyof T] = localizeValue(
          value as Translatable<string>
        ) as DeepLocalizedBundle<T>[keyof T]
      } else {
        // Recursively localize nested objects
        acc[key as keyof T] = localizeBundle(
          value,
          localizeValue
        ) as DeepLocalizedBundle<T>[keyof T]
      }
    } else {
      acc[key as keyof T] = value as DeepLocalizedBundle<T>[keyof T]
    }
    return acc
  }, {} as DeepLocalizedBundle<T>)
}

export const useLocalizedBundle = (bundle: Bundle): DeepLocalizedBundle<Bundle> => {
  const localizeValue = (value: Translatable<string>) => {
    return useLocalizedValue(value) ?? ''
  }

  return localizeBundle(bundle, localizeValue)
}
