import React, { useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import { Tabs, TabsContent } from '@/components/ui/tabs'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import BundleTabList from '@/components/custom/tabs/bundle-tab-list'
import BundleCategoryTab from '@/components/custom/tabs/bundle-category-tab'
import type { Bundle, BundleTab } from '@/types'
import { LazySvg } from '@/components/custom/lazy-svg'
import TelecomPlansGrid1 from './dynamic-island'
import { cn } from '@/lib/utils'

interface TariffSectionProps {
  tabs: BundleTab[]
  isSimple?: boolean
}

const TariffSection: React.FC<TariffSectionProps> = ({ tabs, isSimple = false }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.id || '')
  const [activeCategory, setActiveCategory] = useState<string>(tabs[0]?.categories?.[0]?.id || '')

  const activeTabData = tabs.find((tab: BundleTab) => tab.id === activeTab)
  const bundles: Bundle[] = isSimple
    ? (tabs[0] as unknown as Bundle[])
    : activeTabData?.categories
      ? activeTabData.categories.find((cat) => cat.id === activeCategory)?.bundles || []
      : activeTabData?.bundles || []

  const handleTabChange = useCallback(
    (tabId: string) => {
      setActiveTab(tabId)
      const newTab = tabs.find((tab) => tab.id === tabId)
      setActiveCategory(newTab?.categories?.[0]?.id || '')
    },
    [tabs]
  )

  const handleCategoryChange = useCallback((categoryId: string) => {
    setActiveCategory(categoryId)
  }, [])

  if (!tabs || tabs.length === 0) {
    return <div>No tariff data available.</div>
  }

  if (isSimple && bundles) {
    return (
      <TelecomPlansGrid1
        bundles={bundles}
        className="grid grid-cols-1 gap-[27px] sm:grid-cols-2 lg:grid-cols-3 lg:gap-3.5 xl:grid-cols-4"
      />
    )
  }

  return (
    <div className="mx-auto flex w-full flex-col md:relative md:-top-[1.5rem]">
      <div className="z-10 md:hidden">
        <Select onValueChange={handleTabChange} defaultValue={activeTab}>
          <SelectTrigger className="h-12 w-full rounded-xl">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent>
            {tabs.map((tab) => (
              <SelectItem key={tab.id} value={tab.id}>
                <span className="flex items-center">
                  <LazySvg name={tab.icon} className="size-4 text-primary" />
                  <span className="ml-2 font-medium capitalize">{tab.name}</span>
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <BundleTabList tabs={tabs} />

        {tabs.map((tab) => (
          <TabsContent key={tab.id} value={tab.id} className="mt-6">
            <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              {tab.categories && tab.categories.length > 0 && (
                <motion.div
                  className="w-full md:w-[22%]"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.45 }}
                >
                  <BundleCategoryTab
                    categories={tab.categories}
                    activeCategory={activeCategory}
                    onCategoryChange={handleCategoryChange}
                  />
                </motion.div>
              )}
              <div
                className={
                  tab.categories && tab.categories.length > 0 ? 'w-full md:w-[78%]' : 'w-full'
                }
              >
                {bundles && (
                  <TelecomPlansGrid1
                    bundles={bundles}
                    className={cn(
                      'grid grid-cols-1 gap-[27px] sm:grid-cols-2',
                      tab.categories && tab.categories.length > 0
                        ? 'lg:grid-cols-2 lg:gap-4 xl:grid-cols-3'
                        : 'lg:grid-cols-3 lg:gap-3.5 xl:grid-cols-4'
                    )}
                  />
                )}
              </div>
            </div>
          </TabsContent>
        ))}
      </Tabs>
    </div>
  )
}
export default TariffSection
