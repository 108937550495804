'use client'

import { SimpleSection } from '@/components/custom/simple-section'
import NewsCard from '@/components/custom/news-card'
import { Translatable } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'

export interface NewsSectionProps {
  title?: Translatable<string>
  subtitle?: Translatable<string>
  articles?: {
    id: number
    title: Translatable<string>
    excerpt: Translatable<string>
    content: Translatable<string>
    media: Translatable<string>
    published_at: string
  }[]
}

export default function NewsSection({ title, subtitle, articles = [] }: NewsSectionProps) {
  return (
    <SimpleSection
      top={false}
      title={useLocalizedValue(title || {})}
      subtitle={useLocalizedValue(subtitle || {})}
    >
      <div className="z-10 grid auto-cols-max grid-cols-1 gap-6 px-12 sm:grid-cols-2 sm:px-0 md:gap-10 xl:grid-cols-3">
        {articles.map((article) => (
          <div key={article.id}>
            <NewsCard
              id={article.id}
              title={useLocalizedValue(article.title) || ''}
              excerpt={useLocalizedValue(article.excerpt) || ''}
              media={useLocalizedValue(article.media) || ''}
              publishedAt={article.published_at}
            />
          </div>
        ))}
      </div>
    </SimpleSection>
  )
}
