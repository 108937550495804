import { Block, useBlockRenderer } from '@/hooks/use-block-renderer'
import { BlockRenderer } from '@/components/block-renderer'
import AddressSelects from '@/components/custom/address-selects'
import { usePage } from '@inertiajs/react'
import FlagshipStores from '@/components/custom/flagship-stores'

export default function Stores() {
  const blocks = useBlockRenderer('stores') as Block[]
  const { addresses, flagships } = usePage().props

  return (
    <>
      <BlockRenderer blocks={blocks} />
      <FlagshipStores data={flagships} />
      <AddressSelects data={addresses} />
    </>
  )
}
