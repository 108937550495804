import React from 'react'
import type { BundleCategoryTab } from '@/types'
import { cn } from '@/lib/utils'

interface BundleCategoryTabProps {
  categories: BundleCategoryTab[]
  activeCategory: string
  onCategoryChange: (categoryId: string) => void
}

const BundleCategoryTab: React.FC<BundleCategoryTabProps> = React.memo(
  ({ categories, activeCategory, onCategoryChange }) => {
    return (
      <div className="flex flex-col space-y-2">
        {categories.map((category) => (
          <button
            key={category.id}
            onClick={() => onCategoryChange(category.id)}
            className={cn(
              'rounded-xl p-3 text-left font-medium transition-colors',
              activeCategory === category.id
                ? 'cursor-default bg-primary text-primary-foreground'
                : 'bg-secondary text-secondary-foreground hover:bg-primary hover:text-primary-foreground'
            )}
          >
            {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
          </button>
        ))}
      </div>
    )
  }
)

BundleCategoryTab.displayName = 'BundleCategoryTab'

export default BundleCategoryTab
