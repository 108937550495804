'use client'

import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronUp } from 'lucide-react'
import { Button } from '@/components/ui/button'

interface BackToTopProps {
  threshold?: number
}

export default function BackToTop({ threshold = 300 }: BackToTopProps): React.ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const toggleVisibility = (): void => {
    if (window.scrollY > threshold) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    toggleVisibility()

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [threshold])

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className="fixed bottom-4 right-4 z-50"
        >
          <Button
            onClick={scrollToTop}
            size="icon"
            className="rounded-full bg-foreground text-background shadow-lg dark:bg-primary dark:text-foreground"
            aria-label="Back to top"
          >
            <ChevronUp className="size-5" />
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
