import React from 'react'
import { TabsList, TabsTrigger } from '@/components/ui/tabs'
import type { BundleTab } from '@/types'
import { cn } from '@/lib/utils'
import { LanguageSensitiveLazySvg, LazySvg } from '@/components/custom/lazy-svg'

interface BundleTabListProps {
  tabs: BundleTab[]
}

const BundleTabList: React.FC<BundleTabListProps> = React.memo(({ tabs }) => {
  return (
    <TabsList className="hidden h-fit gap-6 md:flex">
      {tabs.map((tab) => (
        <TabsTrigger
          key={tab.id}
          value={tab.id}
          className={cn(
            'group inline-flex h-[140px] w-[158px] cursor-pointer items-center justify-center whitespace-nowrap rounded-3xl border border-border p-6 text-center text-foreground transition-colors duration-500 hover:border-primary hover:bg-primary hover:text-white',
            'data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow'
          )}
        >
          <div className="flex flex-col items-center gap-4">
            <LanguageSensitiveLazySvg
              name={tab.icon}
              className={cn(
                'size-10 transition-colors',
                'text-primary group-hover:text-primary-foreground',
                'dark:text-primary',
                'group-data-[state=active]:text-primary-foreground'
              )}
            />
            <div className="text-sm font-semibold capitalize">{tab.name}</div>
          </div>
        </TabsTrigger>
      ))}
    </TabsList>
  )
})

BundleTabList.displayName = 'BundleTabList'

export default BundleTabList
