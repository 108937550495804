import * as React from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel'
import { cn } from '@/lib/utils'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import Autoplay from 'embla-carousel-autoplay'
import Fade from 'embla-carousel-fade'

interface RCarouselProps {
  images: string[]
  className?: string
  imageClass?: string
}

const DotIndicator = ({ isActive, onClick }: { isActive: boolean; onClick: () => void }) => (
  <Button
    variant="ghost"
    size="sm"
    className={cn(
      'mx-1 size-2 rounded-full p-0 lg:size-2.5',
      isActive
        ? 'bg-white hover:bg-white dark:bg-primary'
        : 'bg-white/20 hover:bg-white/40 dark:bg-primary/40'
    )}
    onClick={onClick}
  />
)

export default function RCarousel({ images, className, imageClass }: RCarouselProps) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [api, setApi] = React.useState<any>()

  React.useEffect(() => {
    if (!api) {
      return
    }

    api.on('select', () => {
      setCurrentSlide(api.selectedScrollSnap())
    })
  }, [api])

  const handleDotClick = (index: number) => {
    if (api) {
      api.scrollTo(index)
    }
  }

  const isRTL = document.dir === 'rtl'

  return (
    <section className={cn('relative mx-auto', className)}>
      <Carousel
        opts={{
          dragFree: false,
          loop: true,
          direction: isRTL ? 'rtl' : 'ltr',
        }}
        plugins={[
          Autoplay({
            delay: 5000,
            stopOnInteraction: false,
            stopOnMouseEnter: true,
          }),
          Fade(),
        ]}
        setApi={setApi}
        className="w-full"
        dir={isRTL ? 'rtl' : 'ltr'}
      >
        <CarouselContent>
          {images.map((src, index) => (
            <CarouselItem key={index}>
              <Card className="rounded-none border-none bg-transparent shadow-none">
                <CardContent className="flex items-center justify-center p-0">
                  <img
                    src={src}
                    alt={`Slide ${index + 1}`}
                    className={imageClass || 'h-full w-full object-cover'}
                    loading="lazy"
                    fetchPriority="high"
                  />
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      <div className="absolute inset-x-0 bottom-2 flex justify-center">
        <div className="flex items-center justify-between space-x-2 rounded-full bg-black/20 px-3 py-1.5 backdrop-blur-sm dark:bg-black rtl:space-x-reverse">
          <Button
            variant="ghost"
            size="icon"
            className="size-4 shrink-0 rounded-full text-white hover:bg-white/30 hover:text-white hover:backdrop-blur-sm dark:text-primary dark:hover:bg-primary/20"
            onClick={() => api?.scrollPrev()}
          >
            <ChevronLeft className="size-3 lg:size-4 rtl:rotate-180" />
            <span className="sr-only">Previous slide</span>
          </Button>
          <div className="flex flex-1 items-center justify-center">
            {images.map((_, index) => (
              <DotIndicator
                key={index}
                isActive={currentSlide === index}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
          <Button
            variant="ghost"
            size="icon"
            className="size-4 shrink-0 rounded-full text-white hover:bg-white/30 hover:text-white hover:backdrop-blur-sm dark:text-primary dark:hover:bg-primary/20"
            onClick={() => api?.scrollNext()}
          >
            <ChevronRight className="size-3 lg:size-4 rtl:rotate-180" />
            <span className="sr-only">Next slide</span>
          </Button>
        </div>
      </div>
    </section>
  )
}
