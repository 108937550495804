import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageCode, Translatable, Bundle, Tariff } from '@/types'

type LocalizedBundle = {
  [K in keyof Bundle]: Bundle[K] extends Translatable<infer T>
    ? T
    : Bundle[K] extends Array<Tariff>
      ? Array<LocalizedTariff>
      : Bundle[K]
}

type LocalizedTariff = {
  [K in keyof Tariff]: Tariff[K] extends Translatable<infer T> ? T : Tariff[K]
} & { name: string }

function localizeField<T>(
  field: Translatable<T> | T,
  language: LanguageCode,
  fallbackLanguage: LanguageCode
): T | undefined {
  if (typeof field === 'object' && field !== null && 'en' in field) {
    return (field[language] ?? field[fallbackLanguage]) as T
  }
  return field as T
}

export const useLocalizedBundle = (bundle: Bundle): LocalizedBundle => {
  const { i18n } = useTranslation()

  const localizedBundle = useMemo(() => {
    const language = i18n.language as LanguageCode
    const fallbackLanguage: LanguageCode = 'en'

    return Object.entries(bundle).reduce((acc, [key, value]) => {
      if (key === 'tariffs' && Array.isArray(value)) {
        // @ts-ignore
        acc[key] = value.map((tariff) =>
          Object.entries(tariff).reduce((tariffAcc, [tariffKey, tariffValue]) => {
            ;(tariffAcc as any)[tariffKey] = localizeField(tariffValue, language, fallbackLanguage)
            return tariffAcc
          }, {} as LocalizedTariff)
        )
      } else {
        acc[key] = localizeField(value, language, fallbackLanguage)
      }
      return acc
    }, {} as LocalizedBundle)
  }, [bundle, i18n.language])

  return localizedBundle
}

function useLocalizedValue<T>(translations: Translatable<T>): T | null {
  const { i18n } = useTranslation()
  const [value, setValue] = useState<T | null>(null)

  useEffect(() => {
    const language = i18n.language as LanguageCode
    setValue(translations[language] ?? translations['en'] ?? null)
  }, [translations, i18n.language])

  return value
}

export default useLocalizedValue
