import { PropsWithChildren } from 'react'

import { Block, useBlockRenderer } from '@/hooks/use-block-renderer'
import { BlockRenderer } from '@/components/block-renderer'

export default function Home({ children }: PropsWithChildren) {
  const blocks = useBlockRenderer('home') as Block[]

  return <BlockRenderer blocks={blocks} />
}
