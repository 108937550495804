'use client'

import React, { useState, useMemo, useEffect } from 'react'
import { motion } from 'framer-motion'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Address, Translatable } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'
import { useTranslation } from 'react-i18next'
import { toTitleCase } from '@/lib/utils'
import { usePage } from '@inertiajs/react'

interface AddressSelectsProps {
  data: Address[]
}

const LocalizedSelectItem = React.memo(
  ({ value, name }: { value: string; name: Translatable<string> }) => {
    const localizedName = useLocalizedValue(name)
    return <SelectItem value={value}>{localizedName}</SelectItem>
  }
)

const LocalizedAddressItem = React.memo(({ address }: { address: Address }) => {
  const provinceName = useLocalizedValue(address.province.name)
  const districtName = useLocalizedValue(address.district.name)
  const localizedAddress = useLocalizedValue(address.address)
  const localizedName = useLocalizedValue(address.name)

  if (!provinceName || !districtName) {
    return null
  }

  return (
    <motion.li
      className="flex flex-col space-y-1.5 rounded-xl bg-background p-2"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex items-center justify-start space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="size-5 text-primary"
        >
          <path
            fill-rule="evenodd"
            d="M4.5 3.75a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V6.75a3 3 0 0 0-3-3h-15Zm4.125 3a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-3.873 8.703a4.126 4.126 0 0 1 7.746 0 .75.75 0 0 1-.351.92 7.47 7.47 0 0 1-3.522.877 7.47 7.47 0 0 1-3.522-.877.75.75 0 0 1-.351-.92ZM15 8.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15ZM14.25 12a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H15a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15Z"
            clip-rule="evenodd"
          />
        </svg>

        <span className="text-sm text-body">{localizedName || ''}</span>
      </div>
      <a href={`tel:${address.phone}`} className="group flex items-center justify-start space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="size-5 text-primary"
        >
          <path
            fill-rule="evenodd"
            d="M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-4.72 4.72a.75.75 0 1 1-1.06-1.06l4.72-4.72h-2.69a.75.75 0 0 1-.75-.75Z"
            clip-rule="evenodd"
          />
          <path
            fill-rule="evenodd"
            d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
            clip-rule="evenodd"
          />
        </svg>

        <span className="text-sm text-body group-hover:text-primary">{address.phone}</span>
      </a>
      <div className="flex items-center justify-start space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="size-5 shrink-0 text-primary"
        >
          <path
            fill-rule="evenodd"
            d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            clip-rule="evenodd"
          />
        </svg>

        <span className="text-sm text-body">{localizedAddress}</span>
      </div>
    </motion.li>
  )
})

const AddressSelects: React.FC<AddressSelectsProps> = ({ data }) => {
  const { t } = useTranslation()
  const component = usePage().component

  const [selectedProvince, setSelectedProvince] = useState<string | null>(null)
  const [selectedDistrict, setSelectedDistrict] = useState<string | null>(null)
  const [selectedType, setSelectedType] = useState<string | null>(null)

  const provinces = useMemo(() => {
    const uniqueProvinces = new Map<number, { id: number; name: Translatable<string> }>()
    data.forEach((address) => {
      if (address.province && address.province.id && address.province.name) {
        uniqueProvinces.set(address.province.id, address.province)
      }
    })
    return Array.from(uniqueProvinces.values())
  }, [data])

  const districts = useMemo(() => {
    if (!selectedProvince) return []
    const uniqueDistricts = new Map<number, { id: number; name: Translatable<string> }>()
    data
      .filter((address) => address.province?.id.toString() === selectedProvince)
      .forEach((address) => {
        if (address.district && address.district.id && address.district.name) {
          uniqueDistricts.set(address.district.id, address.district)
        }
      })
    return Array.from(uniqueDistricts.values())
  }, [data, selectedProvince])

  const types = useMemo(() => {
    if (!selectedProvince || !selectedDistrict) return []
    const uniqueTypes = new Set<string>()
    data
      .filter(
        (address) =>
          address.province?.id.toString() === selectedProvince &&
          address.district?.id.toString() === selectedDistrict
      )
      .forEach((address) => {
        if (address.type) uniqueTypes.add(address.type)
      })
    return Array.from(uniqueTypes)
  }, [data, selectedProvince, selectedDistrict])

  const filteredAddresses = useMemo(() => {
    if (!selectedProvince || !selectedDistrict || !selectedType) return []
    return data.filter(
      (address) =>
        address.province?.id.toString() === selectedProvince &&
        address.district?.id.toString() === selectedDistrict &&
        address.type === selectedType
    )
  }, [data, selectedProvince, selectedDistrict, selectedType])

  useEffect(() => {
    setSelectedDistrict(null)
    setSelectedType(null)
  }, [selectedProvince])

  useEffect(() => {
    setSelectedType(null)
  }, [selectedDistrict])

  return (
    <motion.div
      className="mx-auto w-full"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: '-50px' }}
      transition={{ duration: 0.5, delay: 0.1 }}
    >
      <Card className="rounded-2xl border-0 bg-secondary/40 shadow-sm backdrop-blur">
        <CardHeader>
          <CardTitle className="h2">
            {component === 'MPaisa' ? t('addressSelector.title2') : t('addressSelector.title')}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-3">
            <Select
              value={selectedProvince || undefined}
              onValueChange={(value) => setSelectedProvince(value)}
              aria-label={t('addressSelector.placeholders.province')}
            >
              <SelectTrigger className="h-10">
                <SelectValue placeholder={t('addressSelector.placeholders.province')} />
              </SelectTrigger>
              <SelectContent>
                {provinces.map((province) => (
                  <LocalizedSelectItem
                    key={province.id}
                    value={province.id.toString()}
                    name={province.name}
                  />
                ))}
              </SelectContent>
            </Select>

            <Select
              value={selectedDistrict || undefined}
              onValueChange={(value) => setSelectedDistrict(value)}
              disabled={!selectedProvince}
              aria-label={t('addressSelector.placeholders.district')}
            >
              <SelectTrigger className="h-10">
                <SelectValue placeholder={t('addressSelector.placeholders.district')} />
              </SelectTrigger>
              <SelectContent>
                {districts.map((district) => (
                  <LocalizedSelectItem
                    key={district.id}
                    value={district.id.toString()}
                    name={district.name}
                  />
                ))}
              </SelectContent>
            </Select>

            <Select
              value={selectedType || undefined}
              onValueChange={(value) => setSelectedType(value)}
              disabled={!selectedProvince || !selectedDistrict}
              aria-label={t('addressSelector.placeholders.type')}
            >
              <SelectTrigger className="h-10">
                <SelectValue placeholder={t('addressSelector.placeholders.type')} />
              </SelectTrigger>
              <SelectContent>
                {types.map((type) => (
                  <SelectItem key={type} value={type}>
                    {toTitleCase(type)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {filteredAddresses.length > 0 ? (
            <motion.ul
              className="space-y-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {filteredAddresses.map((address) => (
                <LocalizedAddressItem key={address.id} address={address} />
              ))}
            </motion.ul>
          ) : (
            <p className="text-center text-gray-500">
              {!selectedProvince
                ? t('addressSelector.selectProvince')
                : !selectedDistrict
                  ? t('addressSelector.selectDistrict')
                  : !selectedType
                    ? t('addressSelector.selectType')
                    : t('addressSelector.noResults')}
            </p>
          )}
        </CardContent>
      </Card>
    </motion.div>
  )
}

export default AddressSelects
