'use client'

import { useState, useEffect, useCallback, useMemo } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { ArrowLeft, X, ArrowRight, ChevronRight } from 'lucide-react'
import WordFadeIn from '@/components/ui/word-fade-in'
import { adjustFont, cn } from '@/lib/utils'
import { LazySvg } from '@/components/custom/lazy-svg'
import PulsatingButton from '@/components/ui/pulsating-button'
import { useToast } from '@/hooks/use-toast'
import { LanguageCode, Translatable } from '@/types'
import useLocalizedValue, { useLocalizedBundle } from '@/hooks/use-localized-value'
import { usePage } from '@inertiajs/react'
import { Bundle } from '@/types'
import {
  BundleDetailLineItem,
  BundleDetailLineItemPlus,
  BundleDetailLineNote,
  BundleDetailLineSocial,
} from './bundle/detail-list'
import { useTranslation } from 'react-i18next'

// import {useLocalizedBundle} from '@/lib/utils'

export interface TelecomPlansGrid1Props {
  bundles: Bundle[]
  title?: Translatable<string> | null
  subtitle?: Translatable<string> | null
  className?: string
}

const imageVariants = {
  initial: { scale: 1 },
  hover: { scale: 1.2 },
}

interface DynamicIslandProps {
  bundle: Bundle
  isExpanded: boolean
  onClick: () => void
  onClose: () => void
  onFormErrorChange: (hasError: boolean) => void
}

// const FormStage = {
//   INITIAL: 'initial',
//   PHONE: 'phone',
//   PIN: 'pin',
// } as const

// type FormStageType = (typeof FormStage)[keyof typeof FormStage]

const DynamicIsland: React.FC<DynamicIslandProps> = ({
  bundle,
  isExpanded,
  onClick,
  onClose,
  onFormErrorChange,
}) => {
  //   const { toast } = useToast()
  const { i18n } = useTranslation()
  const { component, props } = usePage()
  const bundleHeaderMedia = props.bundleHeaderMedia as { [key: string]: string }
  const localizedBundle = useLocalizedBundle(bundle)

  const [titleFontSize, setTitleFontSize] = useState('2.25rem')
  const isTezNet = component === 'TezNet'
  const isBundle = !!bundle.category && bundle.category === 'none_alpha'
  //   const [stage, setStage] = useState<FormStageType>(FormStage.INITIAL)
  //   const [phoneNumber, setPhoneNumber] = useState('')
  //   const [pin, setPin] = useState('')
  const [formError, setFormError] = useState('')

  useEffect(() => {
    if (localizedBundle.title) {
      setTitleFontSize(adjustFont(localizedBundle.title))
    }
  }, [localizedBundle.title])

  const handleFormError = useCallback(
    (error: string) => {
      setFormError(error)
      onFormErrorChange(!!error)
    },
    [onFormErrorChange]
  )

  //   const handleNext = useCallback(() => {
  //     if (stage === FormStage.INITIAL) {
  //       setStage(FormStage.PHONE)
  //     } else if (stage === FormStage.PHONE) {
  //       if (phoneNumber.length !== 10) {
  //         handleFormError('Phone number must be 10 digits')
  //       } else {
  //         handleFormError('')
  //         setStage(FormStage.PIN)
  //       }
  //     }
  //   }, [stage, phoneNumber, handleFormError])

  //   const handleBack = useCallback(() => {
  //     handleFormError('')
  //     setStage((prev) => (prev === FormStage.PHONE ? FormStage.INITIAL : FormStage.PHONE))
  //   }, [handleFormError])

  //   const handleActivate = useCallback(() => {
  //     if (pin.length !== 4) {
  //       handleFormError('PIN must be 4 digits')
  //     } else {
  //       handleFormError('')
  //       console.log('Plan activated:', localizedBundleTitle, 'Phone:', phoneNumber, 'PIN:', pin)
  //       handleClose()
  //       toast({
  //         title: 'Bundle Activated!',
  //         description: 'You will receive a confirmation SMS shortly.',
  //       })
  //     }
  //   }, [pin, localizedBundleTitle, phoneNumber, handleFormError, toast])

  const handleClose = useCallback(() => {
    // setStage(FormStage.INITIAL)
    // setPhoneNumber('')
    // setPin('')
    handleFormError('')
    onClose()
  }, [onClose, handleFormError])

  const renderTeznetDetails = () => (
    <div className="space-y-4 overflow-y-scroll">
      <ul className="flex flex-col items-start justify-center gap-y-2 text-white">
        <li className="flex items-center space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-4 text-white/85"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
              d="M8.99998 5.30971v3.69026l3.39162 3.39163M17 9c0 4.4183-3.5817 8-8 8-4.41828 0-8-3.5817-8-8 0-4.41828 3.58172-8 8-8 4.4183 0 8 3.58172 8 8Z"
            />
          </svg>
          <span>{localizedBundle.validity}</span>
        </li>
        <li className="flex items-center space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-4 text-white/85"
            fill="none"
            viewBox="0 0 22 11"
          >
            <path
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="1.6"
              d="M2.30939 8.65889c1.7468 1.74681 4.57938 1.74681 6.32618 0l3.16263-3.16261-3.16263-3.16262C6.88877.58686 4.05619.58686 2.30939 2.3327c-1.74585 1.74776-1.74585 4.57939 0 6.32619ZM19.8836 8.1911c1.4885-1.48852 1.4885-3.90209 0-5.38965-1.4885-1.48851-3.9021-1.48851-5.3896 0l-2.6949 2.69483L14.494 8.1911c1.4885 1.48851 3.9021 1.48851 5.3896 0Z"
            />
          </svg>
          <span>{localizedBundle.internet_quota}</span>
        </li>
        <li className="flex items-center space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="size-4 text-white/85"
            fill="none"
            viewBox="0 0 22 22"
          >
            <path
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="1.6"
              d="m20.5608 13.66-.06-.04c-.93-.54-1.48-1.54-1.48-2.62v-.03c0-1.08.54-2.08 1.48-2.62l.06-.04c.4-.23.54-.74.31-1.14l-1.64-2.84c-.23-.4-.74-.54-1.14-.31l-.06.04c-.94.54-2.08.51-3.01-.03-.01 0-.02-.01-.03-.02-.93-.54-1.53-1.14-1.53-2.21 0-.46-.37-.8-.83-.8H9.35079c-.46 0-.83.34-.83.8 0 1.08-.59 1.68-1.53 2.21-.01 0-.02.01-.03.02-.93.54-2.08.57-3.01.03l-.06-.04c-.4-.23-.91-.09-1.14.31l-1.64 2.84c-.23.4-.09.91.31 1.14l.07.04c.93.54 1.48 1.54 1.48 2.62V11c0 1.08-.54 2.08-1.48 2.62l-.07.04c-.4.23-.54.74-.31 1.14l1.64 2.84c.23.4.74.54 1.14.31l.06-.04c.94-.54 2.08-.51 3.01.03.01 0 .02.01.03.02.93.54 1.53 1.14 1.53 2.21 0 .46.37.82.83.82h3.28001c.46 0 .83-.36.83-.82 0-1.08.59-1.68 1.53-2.21.01 0 .02-.01.03-.02.93-.54 2.08-.57 3.01-.03l.06.04c.4.23.91.09 1.14-.31l1.64-2.84c.23-.4.09-.91-.31-1.14Zm-9.58.93c-1.99001 0-3.61001-1.61-3.61001-3.61 0-2 1.61-3.61 3.61001-3.61 2 0 3.61 1.61 3.61 3.61 0 2-1.61 3.61-3.61 3.61Z"
            />
          </svg>
          <span>{localizedBundle.internet_note}</span>
        </li>
      </ul>
      <div className="inline-flex items-center text-white">
        <LazySvg name="currency-alt" className="size-5" />
        <div className="text-2xl font-bold">
          {bundle.price}
          {/* <span className="text-sm font-normal">/{bundle.validity}</span> */}
        </div>
      </div>
    </div>
  )

  const renderPlanDetails = () => (
    <div className="space-y-2 overflow-y-scroll">
      <ul className="flex flex-col items-start justify-center gap-y-1 text-white/85">
        <BundleDetailLineItemPlus
          field={localizedBundle.internet_quota}
          note={localizedBundle.internet_note}
          icon={bundle.icons.internet}
          isUnlimited={bundle.unlimited_internet}
        />

        <BundleDetailLineItem field={localizedBundle.fast_internet} icon={bundle.icons.internet} />

        <BundleDetailLineItem
          field={localizedBundle.capped_internet}
          icon={bundle.icons.internet}
        />

        <BundleDetailLineItem
          field={localizedBundle.voice_onnet}
          icon={bundle.icons.voice}
          isUnlimited={bundle.unlimited_voice}
        />
        <BundleDetailLineItem
          field={localizedBundle.voice_offnet}
          icon={bundle.icons.voice_offnet || bundle.icons.voice}
        />

        <BundleDetailLineItem
          field={localizedBundle.sms_onnet}
          icon={bundle.icons.sms}
          isUnlimited={bundle.unlimited_sms}
        />

        <BundleDetailLineItem field={localizedBundle.whatsapp_quota} icon={bundle.icons.whatsapp} />
        <BundleDetailLineItem
          field={localizedBundle.validity}
          icon={bundle.icons.validity}
          // label={'activation'}
        />

        <BundleDetailLineItem
          field={localizedBundle.activation}
          icon={bundle.icons.activation}
          label={'activation'}
        />

        <BundleDetailLineItem
          field={localizedBundle.deactivation}
          icon={bundle.icons.deactivation}
          label={'Deactivation'}
        />

        <BundleDetailLineItem
          field={localizedBundle.ussd}
          icon={bundle.icons.ussd}
          label={'ussd'}
        />

        {isBundle ? (
          <BundleDetailLineNote note={localizedBundle.description} />
        ) : (
          <BundleDetailLineSocial
            field={localizedBundle.social_quota}
            social={localizedBundle.social_quota ? true : false}
            note={localizedBundle.note}
          />
        )}
      </ul>
      <div className="inline-flex items-center space-x-1 text-white rtl:flex-row-reverse">
        <LazySvg name="currency-alt" className="size-5" />
        <div className="text-3xl font-bold">
          {bundle.price}
          {/* <span className="text-sm font-normal">/{localizedBundle.validity}</span> */}
        </div>
      </div>
      {!isTezNet && isBundle && localizedBundle.tariffs && localizedBundle.tariffs.length > 0 && (
        <div className="flex flex-wrap gap-1 py-2">
          {localizedBundle.tariffs.map((tariff) => (
            <Badge
              key={tariff.id}
              variant="secondary"
              className="h-fit bg-background/20 text-xs font-medium text-muted transition-opacity hover:bg-background/30 dark:bg-foreground/10 dark:text-foreground/70"
            >
              {/* @ts-ignore */}
              {tariff.name}
            </Badge>
          ))}
        </div>
      )}
    </div>
  )

  const renderExpandedHeader = () => (
    <div className="flex items-start justify-between">
      <div>
        <h3 className="font-bold leading-tight" style={{ fontSize: titleFontSize }}>
          {localizedBundle.title}
        </h3>

        {/* <div className="my-2 flex flex-wrap gap-1"> */}
        {/* {!isTezNet && bundle.category === 'none_alpha' && bundle.description &&  <span className="text-xs font-medium text-muted">{localizedBundle.description}</span>} */}
        {/* {!isTezNet &&
            localizedBundle.tariffs &&
            localizedBundle.tariffs.length > 0 &&
            localizedBundle.tariffs.slice(0, 5).map((tariff) => (
              <Badge
                key={tariff.id}
                variant="secondary"
                className="h-fit bg-background/20 text-xs font-medium text-muted transition-opacity hover:bg-background/30 dark:text-foreground/70"
              >
                {tariff.name}
              </Badge>
            ))} */}
        {/* </div> */}
      </div>
      <Button
        variant="ghost"
        data-expanded={isExpanded}
        className="group cursor-pointer hover:bg-secondary/20"
        size="icon"
        onClick={(e) => {
          e.stopPropagation()
          handleClose()
        }}
      >
        <X className="size-5 transition-all group-data-[expanded=true]:group-hover:rotate-180 group-data-[expanded=true]:group-hover:text-primary" />
        <span className="sr-only">Close</span>
      </Button>
    </div>
  )

  const renderForm = () => (
    <AnimatePresence mode="wait">
      {/* {(stage === FormStage.PHONE || stage === FormStage.PIN) && (
        <motion.div
          key={stage}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
          className="flex-grow px-0.5 py-3"
        >
          <Label htmlFor={stage} className="text-gray-300">
            {stage === FormStage.PHONE ? 'Phone Number' : 'PIN'}
          </Label>
          <Input
            id={stage}
            type={stage === FormStage.PIN ? 'password' : 'text'}
            className="border-gray-700 bg-gray-800 text-white"
            placeholder={
              stage === FormStage.PHONE ? 'Enter your phone number' : 'Enter 4-digit PIN'
            }
            value={stage === FormStage.PHONE ? phoneNumber : pin}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '')
              if (stage === FormStage.PHONE) {
                setPhoneNumber(value.slice(0, 10))
              } else {
                setPin(value.slice(0, 4))
              }
              handleFormError('')
            }}
          />
          {formError && (
            <p className="mt-2 text-sm text-red-500" role="alert">
              {formError}
            </p>
          )}
        </motion.div>
      )} */}
    </AnimatePresence>
  )

  const renderActionButtons = () => (
    <div className="flex justify-between">
      {/* {stage !== FormStage.INITIAL && (
        <Button variant="secondary" onClick={handleBack}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
      )}
      {stage === FormStage.INITIAL && (
        <PulsatingButton className="w-full" pulseColor="#f0000080" onClick={handleNext}>
          Activate
        </PulsatingButton>
      )}
      {stage === FormStage.PHONE && (
        <Button onClick={handleNext}>
          Next
          <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      )}
      {stage === FormStage.PIN && <Button onClick={handleActivate}>Activate</Button>} */}
    </div>
  )

  return (
    <motion.div
      className={cn(
        'group overflow-hidden rounded-[22px] border-border shadow-lg dark:border',
        isExpanded
          ? 'fixed inset-0 z-[200] m-auto min-h-fit w-[340px] text-white dark:border dark:border-border md:inset-24'
          : 'relative h-[190px] w-[360px] cursor-pointer sm:w-[300px] md:w-[260px] lg:w-[280px] xl:w-[260px]',
        isTezNet ? 'bg-blue-950' : 'bg-black',
        isTezNet && isExpanded ? '!h-fit max-h-[400px]' : '',
        !isBundle && isExpanded ? '!h-fit' : ''
      )}
      layout
      onClick={() => !isExpanded && onClick()}
      whileHover="hover"
    >
      <AnimatePresence mode="wait">
        {!isExpanded ? (
          <>
            {!isTezNet && (
              <motion.div
                className="absolute inset-0 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${bundleHeaderMedia[bundle.type] || bundleHeaderMedia['placeholder']})`,
                }}
                variants={imageVariants}
                transition={{ ease: 'easeOut', duration: 0.3 }}
              />
            )}
            {/* Primary blue background */}
            {isTezNet && (
              <div className="absolute inset-0 bg-blue-800/95 dark:bg-blue-900/95">
                <div className="absolute inset-0">
                  <div className="absolute inset-0 bg-gradient-to-br from-blue-700/80 via-blue-800/90 to-blue-900/95" />
                  <div className="absolute -left-10 top-0 h-80 w-80 rounded-full bg-blue-400/30 mix-blend-soft-light blur-3xl" />
                  <div className="absolute bottom-0 right-0 h-64 w-64 rounded-full bg-red-500/20 mix-blend-overlay blur-xl" />
                  <div className="absolute bottom-0 right-0 h-48 w-48 rounded-full bg-red-500/10 mix-blend-multiply blur-2xl" />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/20 via-transparent to-white/5" />
                </div>
              </div>
            )}
            <div
              className={cn(
                'absolute inset-0 p-4',
                isTezNet ? 'bg-blue-900/10 bg-gradient-to-t' : 'bg-black bg-opacity-50'
              )}
            >
              <div className="flex h-full flex-col justify-between">
                <div>
                  <h3
                    className="font-bold leading-tight text-white"
                    style={{ fontSize: titleFontSize }}
                  >
                    {localizedBundle.title}
                  </h3>
                  <div className="mb-4 flex flex-wrap gap-1">
                    {/* {bundle.features.map((feature, index) => (
                      <Badge
                        key={index}
                        variant="secondary"
                        className="h-5 bg-transparent text-xs font-medium text-muted hover:bg-background/20 dark:border-border dark:text-foreground/70"
                      >
                        {feature}
                      </Badge>
                    ))} */}
                    {isTezNet && (
                      <>
                        <Badge
                          variant="secondary"
                          className="h-5 bg-background/20 text-xs font-medium text-muted transition-opacity hover:bg-background/30 dark:bg-foreground/10 dark:text-foreground/70"
                        >
                          {localizedBundle.internet_quota}
                        </Badge>
                        <Badge
                          variant="secondary"
                          className="h-5 bg-background/20 text-xs font-medium text-muted transition-opacity hover:bg-background/30 dark:bg-foreground/10 dark:text-foreground/70"
                        >
                          {localizedBundle.internet_note}
                        </Badge>
                      </>
                    )}
                    {!isTezNet &&
                      isBundle &&
                      localizedBundle.tariffs &&
                      localizedBundle.tariffs.length > 0 &&
                      localizedBundle.tariffs.slice(0, 3).map((tariff) => (
                        <Badge
                          key={tariff.id}
                          variant="secondary"
                          className="h-fit bg-background/20 text-xs font-medium text-muted transition-opacity hover:bg-background/30 dark:bg-foreground/10 dark:text-foreground/70"
                        >
                          {/* @ts-ignore */}
                          {tariff.name}
                        </Badge>
                      ))}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="inline-flex items-center text-white">
                    <LazySvg name="currency-alt" className="size-5" />
                    <div className="text-2xl font-bold">
                      {bundle.price}
                      <span className="text-sm font-normal">/{localizedBundle.validity}</span>
                    </div>
                  </div>
                  <span className="relative flex items-center justify-start rounded-lg bg-primary/85 py-0.5 pl-1.5 text-xs font-bold text-white opacity-0 transition-opacity duration-300 group-hover:opacity-85">
                    {/* Buy Now */}
                    Details
                    <ChevronRight className="ml-0.5 h-4 w-4 animate-bounce-x" strokeWidth={3} />
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex h-full flex-col space-y-2 p-6">
            {renderExpandedHeader()}
            <div className="flex-grow overflow-y-auto">
              {isTezNet && renderTeznetDetails()}
              {!isTezNet && renderPlanDetails()}
              {renderForm()}
            </div>
            {/* {renderActionButtons()} */}
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default function TelecomPlansGrid1({
  bundles,
  title,
  subtitle,
  className,
}: TelecomPlansGrid1Props) {
  const [expandedPlan, setExpandedPlan] = useState<number | null>(null)
  const [hasFormError, setHasFormError] = useState(false)
  const localizedSectionTitle = useLocalizedValue(title || {})
  const localizedSectionSubtitle = useLocalizedValue(subtitle || {})

  useEffect(() => {
    document.body.style.overflow = expandedPlan !== null ? 'hidden' : 'auto'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [expandedPlan])

  const handleClose = useCallback(() => {
    if (!hasFormError) {
      setExpandedPlan(null)
    }
  }, [hasFormError])

  return (
    <section
      className={cn(
        'flex flex-col items-center justify-center',
        Boolean(localizedSectionTitle?.trim()) || Boolean(localizedSectionSubtitle?.trim())
          ? 'gap-10'
          : ''
      )}
    >
      <div className="text-center">
        {localizedSectionTitle && (
          <WordFadeIn
            className="text-[32px] font-semibold leading-tight tracking-tight text-foreground"
            words={localizedSectionTitle || ''}
            useWhileInView={true}
            viewportOnce={true}
            viewportAmount={1}
            delay={0.3}
          />
        )}
        {localizedSectionTitle && (
          <WordFadeIn
            className="px-10 text-xl font-normal leading-tight tracking-tight text-body"
            words={localizedSectionSubtitle || ''}
            useWhileInView={true}
            viewportOnce={true}
            viewportAmount={1}
            delay={0.05}
          />
        )}
      </div>
      <div
        className={
          className
            ? className
            : 'grid grid-cols-1 justify-center gap-[27px] sm:grid-cols-2 md:grid-cols-3 md:gap-6 xl:grid-cols-4 xl:gap-6'
        }
      >
        {bundles.map((bundle, index) => (
          <motion.div
            initial={index % 2 === 0 ? { rotateY: -90, opacity: 0 } : { rotateX: -90, opacity: 0 }}
            whileInView={index % 2 === 0 ? { rotateY: 0, opacity: 1 } : { rotateX: 0, opacity: 1 }}
            viewport={{ once: true, margin: '100px' }}
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 20,
              delay: 0.175,
            }}
            key={index}
          >
            <DynamicIsland
              bundle={bundle}
              isExpanded={expandedPlan === index}
              onClick={() => setExpandedPlan(index)}
              onClose={() => {
                setHasFormError(false)
                setExpandedPlan(null)
              }}
              onFormErrorChange={setHasFormError}
            />
          </motion.div>
        ))}
      </div>
      {expandedPlan !== null && (
        <div
          className="fixed inset-0 z-[100] bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={(e) => {
            e.preventDefault()
            handleClose()
          }}
        />
      )}
    </section>
  )
}
