'use client'

import { motion } from 'framer-motion'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Store } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'

interface FlagshipStoresProps {
  data: Store[]
}

export default function FlagshipStores({ data }: FlagshipStoresProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card className="border-0 bg-transparent shadow-none">
        <CardHeader className="rounded-xl bg-primary px-3 py-4 text-primary-foreground">
          <CardTitle className="text-sm font-semibold md:text-lg">Flagship Stores</CardTitle>
        </CardHeader>
        <CardContent className="bg-transparent p-0">
          <div className="overflow-x-auto">
            <Table className="[&_tr]:border-0">
              <TableHeader className="[&_tr]:border-0">
                <TableRow className="border-0 bg-transparent hover:bg-transparent">
                  <TableHead className="h-12 border-0 px-3 font-semibold text-foreground/85">
                    Province
                  </TableHead>
                  <TableHead className="h-12 border-0 px-3 font-semibold text-foreground/85">
                    Shop Name
                  </TableHead>
                  <TableHead className="h-12 border-0 px-3 font-semibold text-foreground/85">
                    Contact
                  </TableHead>
                  <TableHead className="h-12 border-0 px-3 font-semibold text-foreground/85">
                    Address
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="[&_tr]:border-0">
                {data.map((store, index) => (
                  <motion.tr
                    key={store.id}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className={`${index % 2 === 0 ? '!rounded-2xl bg-secondary' : 'bg-background'} border-0`}
                  >
                    <TableCell className="h-12 rounded-s-xl border-0 px-3 text-body">
                      {useLocalizedValue(store.province.name)}
                    </TableCell>
                    <TableCell className="h-12 border-0 px-3 text-body">
                      {store.code || useLocalizedValue(store.name)}
                    </TableCell>
                    <TableCell className="h-12 border-0 px-3 text-body">
                      <a
                        href={`tel:${store.phone}`}
                        className="text-body transition-colors hover:text-primary"
                      >
                        {store.phone}
                      </a>
                    </TableCell>
                    <TableCell className="max-w-md rounded-e-xl border-0">
                      {useLocalizedValue(store.address)}
                    </TableCell>
                  </motion.tr>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  )
}
