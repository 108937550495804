import React, { useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { LanguageSensitiveLazySvg, LazySvg } from '@/components/custom/lazy-svg'
import RenderSafeHtml from './render-safe-html'
import useLocalizedValue from '@/hooks/use-localized-value'
import { cn } from '@/lib/utils'
import { Translatable } from '@/types'
import SimpleSection from '@/components/custom/simple-section'
import BundleCard from '@/components/custom/tabs/bundle-card'
import type { Bundle } from '@/types'
import FAQSection from '@/components/sections/faqs-section'
import { usePage } from '@inertiajs/react'
import AddressSelects from '@/components/custom/address-selects'
import TelecomPlansGrid1 from './dynamic-island'

interface FAQItem {
  question: string | null
  answer: string | null
}

interface TabData {
  id: string
  name: Translatable<string>
  icon: string
  richContent?: Translatable<string>
  title?: null | Translatable<string>
  subtitle?: null | Translatable<string>
  plans?: Bundle[]
  tablist?: string
  questions?: Translatable<FAQItem[]>
  faq_icon?: string | null
}
interface TabbedSectionProps {
  tabs: TabData[]
  title?: null | Translatable<string>
  subtitle?: null | Translatable<string>
  className?: null | string
}

const TabTitles: React.FC<TabbedSectionProps> = React.memo(({ tabs }) => {
  return (
    <TabsList className="flex h-fit gap-2 sm:h-full md:gap-6">
      {tabs.map((tab) => (
        <TabsTrigger
          key={tab.id}
          value={tab.id}
          className={cn(
            'group inline-flex h-[100px] w-[130px] cursor-pointer items-center justify-center whitespace-nowrap rounded-2xl border border-border p-4 text-center text-foreground transition-colors duration-500 hover:border-primary hover:bg-primary hover:text-white sm:p-6 md:h-[140px] md:w-[158px] md:rounded-3xl',
            'data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow'
          )}
        >
          <div className="flex flex-col items-center gap-2 sm:gap-4">
            <LanguageSensitiveLazySvg
              name={tab.icon}
              className={cn(
                'size-6 transition-colors sm:size-8 md:size-10',
                'text-primary group-hover:text-primary-foreground',
                'dark:text-primary',
                'group-data-[state=active]:text-primary-foreground'
              )}
            />
            <div className="text-wrap text-xs font-medium capitalize sm:text-sm sm:font-semibold">
              {useLocalizedValue(tab.name)}
            </div>
          </div>
        </TabsTrigger>
      ))}
    </TabsList>
  )
})

TabTitles.displayName = 'TabTitles'

const TabbedSection: React.FC<TabbedSectionProps> = ({ tabs, className }) => {
  const { addresses } = usePage().props

  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.id || '')
  const handleTabChange = useCallback(
    (tabId: string) => {
      setActiveTab(tabId)
    },
    [tabs]
  )

  if (!tabs || tabs.length === 0) {
    return <div>No Data Provided for the current page.</div>
  }

  return (
    <div
      className={cn(
        'mx-auto flex w-full flex-col pb-6 md:relative md:-top-[1.5rem]',
        className,
        addresses ? 'px-0' : ''
      )}
    >
      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <TabTitles tabs={tabs} />

        {tabs.map((tab) => (
          <TabsContent key={tab.id} value={tab.id} className="py-2 md:py-6">
            <SimpleSection
              top={true}
              title={useLocalizedValue(tab.title || {})}
              subtitle={useLocalizedValue(tab.subtitle || {})}
              className="my-4"
            />
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0"
            >
              {tab.plans && (
                <TelecomPlansGrid1
                  bundles={tab.plans}
                  className="grid grid-cols-1 gap-[27px] sm:grid-cols-2 lg:grid-cols-3 lg:gap-3.5 xl:grid-cols-4"
                />
              )}
              {tab.richContent && (
                <RenderSafeHtml
                  className={cn(
                    'prose prose-base max-w-none dark:prose-invert prose-h2:text-foreground prose-p:text-body prose-strong:text-foreground prose-li:marker:text-foreground',
                    'prose-h4:leading-tight prose-h5:mb-0.5 prose-h5:font-medium prose-p:leading-snug prose-h5:md:mb-0 prose-h5:md:text-lg [&>p>span>small]:text-[16px]'
                  )}
                  content={useLocalizedValue(tab.richContent) || ''}
                />
              )}
              {tab.tablist === 'faqs' && tab.questions && (
                <FAQSection questions={tab.questions} icon={tab.faq_icon} adjustGap={false} />
              )}
              {tab.tablist === 'searchable' && addresses && <AddressSelects data={addresses} />}
            </motion.div>
          </TabsContent>
        ))}
      </Tabs>
    </div>
  )
}
export default TabbedSection
