import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface LazySvgProps extends React.SVGProps<SVGSVGElement> {
  name: string
  className?: string
}

const svgCache: Record<string, string> = {}

export const LazySvg: React.FC<LazySvgProps> = ({ name, className, ...props }) => {
  const [svgContent, setSvgContent] = useState<string | null>(null)

  useEffect(() => {
    const loadSvg = async () => {
      if (svgCache[name]) {
        setSvgContent(svgCache[name])
        return
      }

      const paths = [`/storage/navigation-icons/${name}.svg`, `/storage/${name}.svg`]

      for (const path of paths) {
        try {
          const response = await fetch(path, { cache: 'force-cache' })
          if (response.ok) {
            const text = await response.text()
            svgCache[name] = text
            setSvgContent(text)
            return
          }
        } catch (error) {
          console.error(`Failed to load SVG from ${path}:`, error)
        }
      }

      console.error(`SVG not found: ${name}`)
      setSvgContent(null)
    }

    loadSvg()
  }, [name])

  if (!svgContent) {
    return (
      <div>
        <svg
          className={'rounded-lg !text-border ' + className}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" y="0" width="100" height="100" rx="8" ry="8" fill="currentColor">
            <animate
              attributeName="opacity"
              values="0.5;1;0.5"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      </div>
    )
  }

  // Parse the SVG content
  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml')
  const svgElement = svgDoc.documentElement

  // Apply the className and other props
  svgElement.setAttribute('class', className || '')
  Object.entries(props).forEach(([key, value]) => {
    if (typeof value === 'string' || typeof value === 'number') {
      svgElement.setAttribute(key, value.toString())
    }
  })

  // Convert the SVG element back to a string
  const serializer = new XMLSerializer()
  const svgString = serializer.serializeToString(svgElement)

  return <span dangerouslySetInnerHTML={{ __html: svgString }} />
}

export const LanguageSensitiveLazySvg: React.FC<LazySvgProps> = (props) => {
  const { i18n } = useTranslation()
  const [key, setKey] = useState(0)

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [i18n.language])

  return <LazySvg key={key} {...props} />
}

// export default LazySvg

// import React, { ComponentProps, FC, useEffect, useRef, useState, useCallback } from 'react'
// import { useTranslation } from 'react-i18next'

// interface LazySvgProps extends ComponentProps<'svg'> {
//   name: string
//   dynamic?: boolean
//   className?: string
// }

// // Cache for imported SVGs
// const svgCache: Record<string, React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined> = {}

// const useLazySvgImport = (name: string, dynamic: boolean = false) => {
//   const [SvgComponent, setSvgComponent] = useState<React.ComponentType<
//     React.SVGProps<SVGSVGElement>
//   > | null>(null)
//   const importingRef = useRef(false)
//   const extractNameAndPath = (name: string) => {
//     const lastSlashIndex = name.lastIndexOf('/')
//     if (lastSlashIndex === -1) {
//       return { path: '', name }
//     }
//     const path = name.substring(0, lastSlashIndex)
//     const iconName = name.substring(lastSlashIndex + 1)
//     return { path, name: iconName }
//   }
//   const { path, name: iconName } = extractNameAndPath(name)

//   const importIcon = useCallback(async () => {
//     if (importingRef.current) return
//     importingRef.current = true

//     try {
//       let module
//       if (dynamic) {
//         if (path !== '') {
//           module = await import(`../../../../public/storage/${path}/${iconName}.svg?react`)
//         } else {
//           module = await import(`../../../../public/storage/navigation-icons/${iconName}.svg?react`)
//         }
//       } else {
//         module = await import(`@/Components/icons/${iconName}.svg?react`)
//       }
//       const ImportedIcon = module.default || module
//       if (typeof ImportedIcon === 'function' || typeof ImportedIcon === 'object') {
//         const Component = (props: React.SVGProps<SVGSVGElement>) =>
//           React.createElement(ImportedIcon, props)
//         svgCache[iconName] = Component
//         setSvgComponent(() => Component)
//       } else {
//         console.error(`Imported icon ${iconName} is not a valid React component`)
//       }
//     } catch (error) {
//       console.error(`Failed to import icon: ${iconName}`, error)
//     } finally {
//       importingRef.current = false
//     }
//   }, [iconName, dynamic])

//   useEffect(() => {
//     if (svgCache[iconName]) {
//       setSvgComponent(
//         () => svgCache[iconName] as React.ComponentType<React.SVGProps<SVGSVGElement>>
//       )
//     } else {
//       importIcon()
//     }
//   }, [iconName, importIcon])

//   return SvgComponent
// }

// export const LazySvg: FC<LazySvgProps> = ({ name, dynamic = false, className, ...props }) => {
//   const SvgComponent = useLazySvgImport(name, dynamic)

//   if (!SvgComponent) {
//     return <span className={className} />
//   }

//   return <SvgComponent {...props} className={className} />
// }

// export const LanguageSensitiveLazySvg: FC<LazySvgProps> = (props) => {
//   const { i18n } = useTranslation()
//   const [key, setKey] = useState(0)

//   useEffect(() => {
//     setKey((prevKey) => prevKey + 1)
//   }, [i18n.language])

//   return <LazySvg key={key} {...props} />
// }

// import React, { FC, useEffect, useState, useCallback } from 'react'

// interface LazySvgProps {
//   name: string // The icon name or path
//   dynamic?: boolean // Whether the icon is user-uploaded (dynamic)
//   className?: string // TailwindCSS classes
// }

// const svgCache: Record<string, string | null> = {}

// export const LazySvg: FC<LazySvgProps> = ({ name, dynamic = false, className }) => {
//   const [svgContent, setSvgContent] = useState<string | null>(null)

//   const fetchSvg = useCallback(async () => {
//     if (svgCache[name]) {
//       setSvgContent(svgCache[name])
//       return
//     }

//     try {
//       const baseUrl = window.location.origin
//       const url = dynamic
//         ? `${baseUrl}/storage/${name}.svg` // For user-uploaded icons
//         : `${baseUrl}/storage/navigation-icons/${name}.svg` // For pre-bundled icons

//       const response = await fetch(url)
//       if (response.ok) {
//         const content = await response.text()
//         svgCache[name] = content // Cache the SVG content
//         setSvgContent(content)
//       } else {
//         console.error(`Failed to load SVG: ${name}`)
//         svgCache[name] = null // Cache failure to avoid redundant fetches
//       }
//     } catch (error) {
//       console.error(`Error fetching SVG: ${name}`, error)
//       svgCache[name] = null
//     }
//   }, [name, dynamic])

//   useEffect(() => {
//     fetchSvg()
//   }, [fetchSvg])

//   if (!svgContent) {
//     return <span className={className} />
//   }

//   return (
//     <span
//       className={className}
//       dangerouslySetInnerHTML={{ __html: svgContent }} // Render SVG content inline
//     />
//   )
// }

// export const LanguageSensitiveLazySvg: FC<LazySvgProps> = (props) => {
//   const { name, dynamic, className } = props

//   // Dynamically re-render the icon when the language changes
//   const [key, setKey] = useState(0)

//   useEffect(() => {
//     setKey((prevKey) => prevKey + 1)
//   }, [name]) // Adjust to re-fetch on icon name changes, if required

//   return <LazySvg key={key} name={name} dynamic={dynamic} className={className} />
// }
