// import { usePage } from '@inertiajs/react'
// import { Link } from '@inertiajs/react'

// import {
//   Accordion,
//   AccordionContent,
//   AccordionItem,
//   AccordionTrigger,
// } from '@/components/ui/accordion'
// import { Sheet, SheetContent, SheetTitle } from '@/components/ui/sheet'
import LanguageSwitch from './LanguageSwitch'
// import ThemeSwitch from './ThemeSwitch'
// import SearchDrawer from './SearchDrawer'
// import { cn } from '@/lib/utils'
// import { NavItem } from '@/types'
// import useLocalizedValue from '@/hooks/use-localized-value'

// interface MobileNavProps {
//   isOpen: boolean
//   setIsOpen: (isOpen: boolean) => void
//   navData: NavItem[]
// }

// export default function Mobile({ isOpen, setIsOpen, navData }: MobileNavProps) {
//   const { component } = usePage()
//     console.log('navData', navData)
//   return (
//     <Sheet open={isOpen} onOpenChange={setIsOpen}>
//       <SheetContent side="left" className="w-[300px] p-0 sm:w-[400px]">
//         <SheetTitle className="sr-only">Mobile Navigation Menu</SheetTitle>
//         <nav className="flex h-full flex-col bg-background dark:bg-gray-950">
//           <div className="flex items-center justify-start p-4">
//             <Link href="/" className="flex items-center" onClick={() => setIsOpen(false)}>
//               <span className="text-xl font-bold text-primary">Roshan</span>
//             </Link>
//           </div>
//           <div className="flex-grow space-y-4 overflow-y-auto p-4">
//             <Accordion type="single" collapsible className="w-full">
//               <AccordionItem value="personal" className="border-none">
//                 <AccordionTrigger className="justify-start py-2 text-sm font-medium hover:text-primary hover:no-underline">
//                   Personal
//                 </AccordionTrigger>
//                 <AccordionContent>
//                   <div className="ml-4 space-y-2">
//                     {navData.Personal.map((item: NavItem) => (
//                       <Link
//                         key={item.order}
//                         href={item.href}
//                         onClick={() => setIsOpen(false)}
//                         className="block py-2 text-sm hover:text-primary dark:text-gray-200 dark:hover:bg-gray-900 dark:hover:text-primary"
//                       >
//                         {useLocalizedValue(item.name)}
//                       </Link>
//                     ))}
//                   </div>
//                 </AccordionContent>
//               </AccordionItem>
//               <AccordionItem value="business" className="border-none">
//                 <AccordionTrigger className="justify-start py-2 text-sm font-medium hover:text-primary hover:no-underline">
//                   Business
//                 </AccordionTrigger>
//                 <AccordionContent>
//                   <div className="ml-4 space-y-2">
//                   {navData.Business.map((item: NavItem) => (
//                       <Link
//                         key={item.order}
//                         href={item.href}
//                         onClick={() => setIsOpen(false)}
//                         className="block py-2 text-sm hover:text-primary dark:text-gray-200 dark:hover:bg-gray-900 dark:hover:text-primary"
//                       >
//                         {useLocalizedValue(item.name)}
//                       </Link>
//                     ))}
//                   </div>
//                 </AccordionContent>
//               </AccordionItem>
//             </Accordion>
//             <div className="space-y-2">
//             {navData.default.map((item: NavItem) => (
//                       <Link
//                         key={item.order}
//                         href={item.href}
//                         onClick={() => setIsOpen(false)}
//                         className="block py-2 text-sm hover:text-primary dark:text-gray-200 dark:hover:bg-gray-900 dark:hover:text-primary"
//                       >
//                         {useLocalizedValue(item.name)}
//                       </Link>
//                     ))}
//             </div>
//           </div>
//           <div className="flex items-center justify-between bg-secondary p-4">
//             {/* <LanguageSwitch /> */}
//             <ThemeSwitch />
//           </div>
//         </nav>
//       </SheetContent>
//     </Sheet>
//   )
// }
import { useEffect, useState } from 'react'
import { usePage } from '@inertiajs/react'
import { Link } from '@inertiajs/react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Sheet, SheetClose, SheetContent, SheetTitle } from '@/components/ui/sheet'
import ThemeSwitch from './ThemeSwitch'
import { cn } from '@/lib/utils'
import { NavItem, Navigation } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'
import ApplicationLogo from '@/components/ApplicationLogo'
import { useTranslation } from 'react-i18next'

interface MobileNavProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  navData: Navigation['header']
}

export default function Mobile({ isOpen, setIsOpen, navData }: MobileNavProps) {
  const { url } = usePage()
  const { i18n } = useTranslation()
  const [activeGroup, setActiveGroup] = useState<string | null>(null)
  const [sheetSide, setSheetSide] = useState<'left' | 'right'>('left')

  useEffect(() => {
    setSheetSide(i18n.dir() === 'rtl' ? 'right' : 'left')
  }, [i18n.dir()])

  if (!navData) return null

  const sortedNavData = Object.entries(navData).sort(([, a], [, b]) => {
    const minOrderA = Math.min(...a.map((item) => item.order))
    const minOrderB = Math.min(...b.map((item) => item.order))
    return minOrderA - minOrderB
  })

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent side={sheetSide} className="w-[300px] p-0 sm:w-[400px]">
        <SheetTitle className="sr-only">Mobile Navigation Menu</SheetTitle>
        <nav className="flex h-full flex-col bg-background dark:bg-gray-950">
          <div className="flex items-center justify-start px-4 pt-4">
            <Link href="/" className="flex items-center" onClick={() => setIsOpen(false)}>
              <ApplicationLogo className="block h-9 w-auto transition-colors duration-300" />
            </Link>
          </div>
          <div className="flex-grow space-y-4 overflow-y-auto p-4">
            <Accordion
              type="single"
              collapsible
              value={activeGroup || ''}
              onValueChange={setActiveGroup}
            >
              {sortedNavData.map(([key, items]) =>
                key === 'default' ? (
                  <DefaultMenuItems key={key} items={items} url={url} setIsOpen={setIsOpen} />
                ) : (
                  <GroupMenuItem
                    key={key}
                    groupKey={key}
                    items={items}
                    url={url}
                    setIsOpen={setIsOpen}
                  />
                )
              )}
            </Accordion>
          </div>
          <div className="flex items-center justify-between bg-secondary p-4">
            {/* <LanguageSwitch /> */}
            <ThemeSwitch />
          </div>
        </nav>
      </SheetContent>
    </Sheet>
  )
}

function DefaultMenuItems({
  items,
  url,
  setIsOpen,
}: {
  items: NavItem[]
  url: string
  setIsOpen: (isOpen: boolean) => void
}) {
  return (
    <div className="">
      {items.map((item) => (
        <Link
          key={item.order}
          href={item.href}
          onClick={() => setIsOpen(false)}
          className={cn(
            'block py-2 text-sm text-foreground hover:text-primary',
            url === item.href ? 'text-primary dark:text-primary' : ''
          )}
        >
          {useLocalizedValue(item.name)}
        </Link>
      ))}
    </div>
  )
}

function GroupMenuItem({
  groupKey,
  items,
  url,
  setIsOpen,
}: {
  groupKey: string
  items: NavItem[]
  url: string
  setIsOpen: (isOpen: boolean) => void
}) {
  const parentName = useLocalizedValue(items.find((item) => item.group === groupKey)?.parent || {})

  return (
    <AccordionItem value={groupKey} className="border-none">
      <AccordionTrigger className="justify-start py-2 text-sm font-medium text-foreground hover:text-primary hover:no-underline">
        {parentName}
      </AccordionTrigger>
      <AccordionContent>
        <div className="ml-4">
          {items.map((item) => (
            <Link
              key={item.order}
              href={item.href}
              onClick={() => setIsOpen(false)}
              className={cn(
                'block py-2 text-sm text-foreground hover:text-primary',
                url === item.href ? 'text-primary dark:text-primary' : ''
              )}
            >
              {useLocalizedValue(item.name)}
            </Link>
          ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}
